import React, { useEffect, useState } from "react";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Link} from "react-router-dom";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";
import CentralHeader from "../../../shared/components/CentralHeader";
import StatBox from "../../../shared/components/StatBox";
dayjs.extend(relativeTime);

function Home() {
  const [logs, setLogs] = useState([]);
  const [eventLogs, setEventLogs] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    Promise.all([
      API.logs.all(),
      API.dash(),
      API.logs.eventLogs()
    ])
      .then((res) => {
        setLogs(res[0]);
        setData(res[1]);
        setEventLogs(res[2]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Logs = (theLogs) => {
    const list = theLogs.map((l) => {
        let typeColor = "";
        switch (l.type.toLowerCase()) {
          case "info":
            typeColor = "bg-blue-500";
            break;
          case "warning":
            typeColor = "bg-orange-500";
            break;
          case "alert":
            typeColor = "bg-red-500";
            break;
          default:
            typeColor = "bg-grey-500";
            break;
        }
        return (
          <tr key={l._id} className="text-gray-200">
            <td className="px-2 py-4 text-xs ">
              {dayjs(l.createdAt).fromNow()}
            </td>
            {/*<td className="px-2 py-4 ">*/}
            {/*  <span*/}
            {/*    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}*/}
            {/*  >*/}
            {/*    {l.type.toUpperCase()}*/}
            {/*  </span>*/}
            {/*</td>*/}
            <td className="px-2 py-4 text-xs tracking-wider ">{l.message}</td>
            {/*<td className="px-2 py-4 text-xs ">*/}
            {/*  {l.application !== undefined &&*/}
            {/*  l.application !== null &&*/}
            {/*  l.application.name !== undefined*/}
            {/*    ? l.application.name*/}
            {/*    : "[NO APPLICATION]"}*/}
            {/*</td>*/}
            <td className="px-2 py-4 text-right text-xs ">
              {l.user !== undefined && l.user !== null ? <Link to={`/organisations/manage/${l.user.organisation}/team/${l.user._id}`}>{(l.user.fullName !== undefined && l.user.fullName !== null ? l.user.fullName : l.user._id)}</Link> : ""}
            </td>
            <td className="px-2 py-4 text-right text-xs ">
              {l.actioned_by !== undefined && l.actioned_by !== null ? (l.actioned_by.fullName !== undefined && l.actioned_by.fullName !== null ? l.actioned_by.fullName : l.actioned_by._id) : ""}
            </td>
          </tr>
        );
      });
    if(list.length === 0){
      return (
        <div className={"p-2"}>There are no log entries to show.</div>
      )
    }
    return (
      <table className="min-w-full divide-y divide-gray-800">
        <thead>
          <tr className="bg-gray-900">
            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            {/*<th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
            {/*  Type*/}
            {/*</th>*/}
            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Message
            </th>
            {/*<th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
            {/*  System*/}
            {/*</th>*/}
            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              User
            </th>
            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Action By
            </th>
          </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-200">{list}</tbody>
      </table>
    );
  };
  const EventLogs = () => {
    const list = eventLogs.map((l) => {
        let typeColor = "";
        switch (l.level.toLowerCase()) {
          case "info":
            typeColor = "bg-blue-500";
            break;
          case "warning":
            typeColor = "bg-orange-500";
            break;
          case "alert":
            typeColor = "bg-red-500";
            break;
          default:
            typeColor = "bg-grey-500";
            break;
        }
        return (
          <tr key={l._id} className="text-gray-200">
            <td className="px-2 py-4 text-xs">{dayjs(l.createdAt).fromNow()}</td>
            <td className="px-2 py-4">
              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}>{l.level.toUpperCase()}</span>
            </td>
            {/*<td className="px-2 py-4">{l.type}</td>*/}
            <td className="px-2 py-4 text-xs tracking-wider">{l.message}</td>
          </tr>
        );
      });
    return (
      <table className="min-w-full divide-y divide-gray-800">
        <thead>
        <tr className="bg-gray-900">
          <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date
          </th>
          <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Level
          </th>
          {/*<th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
          {/*  Type*/}
          {/*</th>*/}
          <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Message
          </th>
        </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-200">{list}</tbody>
      </table>
    );
  };

  return (
    <div>
      <CentralHeader/>

      <div className="text-center text-xl">Stats</div>
      <div className="p-4 grid grid-cols-4 text-center gap-2">
        <StatBox label={"Organisations"} value={data.orgs} loading={loading}/>
        <StatBox label={"Active Users"} value={data.users} loading={loading}/>
        <StatBox label={"Lockouts"} value={data.lockouts} loading={loading}/>
        <StatBox label={"Disabled Users"} value={data.disabled} loading={loading}/>
      </div>
      <div className="text-center text-xl">Login Stats</div>
      <div className="p-4 grid grid-cols-4 text-center gap-2">
        <StatBox label={"New UI"} value={data.signin_new} loading={loading} />
        <StatBox label={"New via Office 365"} value={data.signin_entra} loading={loading} />
        <StatBox label={"New via existing session"} value={data.signin_sso} loading={loading} />
        <StatBox label={"Old UI"} value={data.signin_old} loading={loading} />
      </div>

      <div className="pt-4 flex flex-col sm:flex-row">
        <div className="px-4 w-full sm:w-1/2">
          <div className="text-xl text-center pb-2">Alert Logs</div>
          <div className="rounded-md border border-gray-700">
            {loading ? <Loader/> : Logs(logs.filter((log) => log.type === "alert"))}
          </div>
          <div className="text-xl text-center pb-2 pt-2">Warning Logs</div>
          <div className="rounded-md border border-gray-700">
            {loading ? <Loader/> : Logs(logs.filter((log) => log.type === "warning"))}
          </div>
        </div>
        <div className="px-4 w-full sm:w-1/2">
          <div className="text-xl text-center pb-2">Event Logs</div>
          <div className="rounded-md border border-gray-700">
            {loading ? <Loader/> : EventLogs()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
